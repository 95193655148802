<template>
  <div v-if="table_permissions" class="custom-content-height">
    <v-data-table
      v-model="selected"
      fixed-header
      light
      dense
      :height="getvh(52)"
      show-select
      :single-select="disableSelectAllCheckbox"
      :items="items"
      :headers="headers"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortOrder"
      :items-per-page="itemPerPage"
      item-key="id.id"
      class="mx-2 mt-0 font-weight-bold"
      hide-default-footer
      @dblclick:row="showDetail"
      mobile-breakpoint="40"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE!</template>
      <template v-slot:no-results> NO RESULTS HERE!</template>
      <!--    no-data end-->
      <!--    table top-->
      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 pt-2 d-flex justify-space-between align-center flex-wrap bg-white position-sticky top-0 zindex-1 row"
        >
          <div class="col-12 col-md-1 px-0">
            <datatable-detail
              :table_properties="table_properties"
              grid="col-12"
              v-if="$vuetify.breakpoint.mdAndUp"
            ></datatable-detail>
            <div
              v-else
              class="d-flex align-center justify-space-between mb-3 w-100"
            >
              <datatable-detail
                :table_properties="table_properties"
              ></datatable-detail>
              <b-button
                depressed
                small
                variant="outline-primary"
                class="mb-1 btn--tools btn-sm"
                @click="toolBarVisionState = !toolBarVisionState"
              >
                TOOLS
              </b-button>
            </div>
          </div>

          <div class="col-12 col-md-11">
            <div
              v-show="isToolbarVisiable"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              class="card-toolbar ma-0 pa-0 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
            >
              <!--begin::Toll buttons-->
              <div class="row justify-content-xl-end">
                <div
                  class="col-6 col-sm-4 col-md-auto px-1 pt-md-0"
                  v-if="permissionChecker('export')"
                >
                  <button
                    @click="$refs.exportSidebar.$data.exportSidebar = true"
                    class="btn btn--export-filter w-100"
                  >
                    <span class="svg-icon">
                      <v-icon size="18">mdi-export-variant</v-icon>
                    </span>
                    Export
                  </button>
                </div>
                <div
                  class="col-6 col-sm-4 col-md-auto px-1 pt-md-0"
                  v-if="filters.length > 0"
                >
                  <button
                    @click="$refs.filterSidebar.$data.filterSidebar = true"
                    class="btn btn--export-filter w-100"
                  >
                    <span class="svg-icon">
                      <v-icon size="18">mdi-filter</v-icon>
                    </span>
                    Filter
                  </button>
                </div>

                <div class="col-6 col-sm-4 col-md-auto px-1 pt-md-0">
                  <v-menu
                    content-class="m-0 p-0"
                    transition="scale-transition"
                    offset-y
                    bottom
                    nudge-bottom="5"
                    :left="$vuetify.rtl"
                    :right="!$vuetify.rtl"
                    rounded
                    max-height="75%"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <button
                        v-on="on"
                        v-bind="attrs"
                        class="poppins btn btn--export-filter px-5"
                      >
                        <span class="svg-icon">
                          <v-icon size="18">mdi-filter</v-icon>
                        </span>
                        <span>{{ getQFilterStatusLabel }}</span>
                        <span class="svg-icon mr-0 ml-2">
                          <v-icon size="18">mdi-chevron-down</v-icon>
                        </span>
                      </button>
                    </template>
                    <div class="py-4 w-175px bg-white px-3">
                      <div
                        class="drop-item poppins px-3 rounded-lg cursor-pointer"
                        @click="() => onStatusFilterChange(false)"
                      >
                        <div class="py-2 w-100 poppins font-size-sm">All</div>
                      </div>
                      <div
                        class="drop-item poppins px-3 rounded-lg cursor-pointer my-1"
                        v-for="data in status_filter_data"
                        :key="data.index"
                        @click="() => onStatusFilterChange(data.index)"
                      >
                        <div class="py-2 w-100 poppins font-size-sm">
                          {{ data.text }}
                        </div>
                      </div>
                    </div>
                  </v-menu>
                </div>
                <div
                  class="col-6 col-sm-4 col-md-auto px-1 pt-md-0"
                  v-if="permissionChecker('update_order_status')"
                >
                  <button
                    class="btn btn--export-filter w-100"
                    @click.prevent="updateStatus(null, true)"
                  >
                    <span class="svg-icon d-none d-sm-inline-block">
                      <v-icon size="18">mdi-refresh</v-icon>
                    </span>
                    Update Status
                  </button>
                  <ChangeStatus
                    :refresher="updateTableContent"
                    :pageLoader="pageLoad"
                    ref="changeStatus"
                    :ids="updateStatusItemId"
                    :isMultiple="getIsUpdateStatusItemsMultiple"
                  ></ChangeStatus>
                </div>
                <UploadSelect
                  v-if="permissionChecker('upload')"
                  :types="$store.getters.getORDERSUploadType"
                  :actionFunction="showUploadModal"
                  :class="{ 'col-6': $vuetify.breakpoint.xsOnly }"
                ></UploadSelect>
                <!-- <div
                  class="col-6 col-sm-4 col-md-auto px-1 pt-md-0"
                  v-if="permissionChecker('upload')"
                >
                  <button
                    class="btn btn--export-filter w-100"
                    @click.prevent="showUploadModal"
                  >
                    <span class="svg-icon">
                      <v-icon size="18">mdi-cloud-upload</v-icon>
                    </span>
                    Import
                  </button>
                </div> -->
                <!--                <div-->
                <!--                  class="col-6 col-sm-4 col-md-auto px-1 pt-md-0"-->
                <!--                  v-if="permissionChecker('create')"-->
                <!--                >-->
                <!--                  <button-->
                <!--                    class="btn btn&#45;&#45;print-upload w-100"-->
                <!--                    @click.prevent="showAddItemModal"-->
                <!--                  >-->
                <!--                    <span class="svg-icon">-->
                <!--                      <v-icon size="18">mdi-package-variant</v-icon>-->
                <!--                    </span>-->
                <!--                    Create Order-->
                <!--                  </button>-->
                <!--                </div>-->
                <v-menu
                  content-class="m-0 p-0"
                  transition="scale-transition"
                  offset-y
                  bottom
                  nudge-bottom="5"
                  auto
                  rounded
                  v-if="permissionChecker('create')"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="col-6 col-sm-4 col-md-auto px-1 pt-md-0">
                      <button
                        v-bind="attrs"
                        v-on="on"
                        class="btn btn--export-filter w-100"
                      >
                        <span class="svg-icon">
                          <v-icon size="18">mdi-package-variant</v-icon>
                        </span>
                        Create Order
                      </button>
                    </div>
                    <AddItem
                      :refresher="updateTableContent"
                      :pageLoader="pageLoad"
                      ref="addItem"
                    />
                    <AddItem2
                      :refresher="updateTableContent"
                      :pageLoader="pageLoad"
                      ref="addItem2"
                    />
                  </template>
                  <div class="py-2 w-175px bg-white px-3">
                    <div
                      class="drop-item poppins px-3 rounded-lg cursor-pointer my-4 bg-light bg-hover-light-info"
                      v-for="(data, index) in [
                        { name: 'Order B2B Transfer', type: 'b2b' },
                        { name: 'Order B2C', type: 'b2c' },
                      ]"
                      :key="index"
                      @click="() => showAddItemModal(data.type)"
                    >
                      <div class="py-2 w-100 poppins font-size-md text-center">
                        {{ data.name }}
                      </div>
                    </div>
                  </div>
                </v-menu>

                <div class="col-6 col-sm-4 col-md-auto px-1 pt-md-0">
                  <Print :items="selected" :types="types" class="w-100"></Print>
                </div>
                <div class="col-6 col-sm-4 col-md-auto px-1 pt-md-0">
                  <ActionsButton
                    v-if="actionsButtonTypes.length > 0"
                    :items="selected"
                    :types="actionsButtonTypes"
                    :refresher="updateTableContent"
                    :class="{ 'col-6': $vuetify.breakpoint.xsOnly }"
                  />
                </div>
              </div>
              <!--end::Toll buttons-->
            </div>
          </div>
        </div>
        <!-- Export Sidebar start -->
        <ExportSidebar
          v-if="exportHeaders.length > 0"
          ref="exportSidebar"
          :exportHeaders="exportHeaders"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--        end::Export Sidebar-->

        <!-- Filter Sidebar start -->
        <FilterSidebar
          v-if="filters.length > 0"
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--end::Filter Sidebar-->

        <!--        <hr />-->
        <Others ref="otherData" :updateDatatable="updateTableContent"></Others>
      </template>
      <!-- column -->
      <template
        v-if="!disableSelectAllCheckbox"
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="allSelected"
            @click="selectAll"
          />
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>

      <template #item.id="{ item, value }">
        <span class="first-text" :id="value.id">
          <v-tooltip bottom v-if="item.id.detail.merged_order">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span>{{ value.id }}</span>
                <v-icon>mdi-file</v-icon>
              </div>
            </template>
            <span>Merged with Order:{{ item.id.detail.merged_order }}</span>
          </v-tooltip>
          <div v-else>{{ value.id }}</div>
        </span>
      </template>

      <template #item.systemId="{ item, value }">
        <span class="second-text" :id="value.systemId">
          <v-tooltip bottom v-if="item.id.detail.split_order">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span>{{ value }}</span>
                <v-icon>mdi-file</v-icon>
              </div>
            </template>
            <span>{{ item.id.detail.split_order }}</span>
          </v-tooltip>
          <div v-else>{{ value }}</div>
        </span>
      </template>

      <template #item.action="{ item }">
        <v-menu left transition="scale-transition" offset-y bottom rounded>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
            >
              Actions
              <span class="svg-icon svg-icon-5 m-0">
                <v-icon small>mdi-chevron-down</v-icon>
              </span>
            </a>
          </template>
          <v-card class="poppins py-1" elevation="1">
            <div
              v-if="
                permissionChecker('reopen') && item.id.detail.actions.reopen
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.reopen"
                :class="
                  !item.id.detail.actions.reopen ? 'text-muted' : 'text-dark'
                "
                @click="() => actionReopen(item.id.id)"
                text
              >
                Remove POD
              </v-btn>
            </div>

            <!-- <div v-if="permissionChecker('update')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.update"
                :class="
                  !item.id.detail.actions.update ? 'text-muted' : 'text-dark'
                "
                @click="editItem(item.id.id)"
                text
                >Edit</v-btn
              >
            </div> -->
            <div
              v-if="
                permissionChecker('update_order_status') &&
                item.id.detail.actions.update_order_status
              "
            >
              <v-btn
                class="w-100 text-left px-0 px-sm-2"
                :disabled="!item.id.detail.actions.update_order_status"
                :class="
                  !item.id.detail.actions.update_order_status
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => updateStatus(item)"
                text
                >Update Status
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('update') && item.id.detail.actions.update
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.update"
                :class="
                  !item.id.detail.actions.update ? 'text-muted' : 'text-dark'
                "
                @click="
                  editItem(
                    item.id.id,
                    item.order_type,
                    !item.id.detail.actions.update
                  )
                "
                text
                >Edit
              </v-btn>
            </div>
            <div
              v-if="permissionChecker('view') && item.id.detail.actions.show"
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.show"
                :class="
                  !item.id.detail.actions.show ? 'text-muted' : 'text-dark'
                "
                @click="() => showItemDetail(item)"
                text
                >View
              </v-btn>
            </div>

            <div
              v-if="
                permissionChecker('update_3pl_awb') &&
                item.id.detail.actions.update_3pl_awb
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.update_3pl_awb"
                :class="
                  !item.id.detail.actions.update_3pl_awb
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => update3PLAWB(item.id.id)"
                text
                >Update Order
              </v-btn>
            </div>

            <div
              v-if="
                permissionChecker('upload_doc') &&
                item.id.detail.actions.upload_doc
              "
            >
              <v-btn
                class="w-100 text-left text-dark"
                @click="() => showUploadDocModal(item)"
                text
                >Upload doc
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('order_vehicles') &&
                item.id.detail.actions.order_vehicles
              "
            >
              <v-btn
                class="w-100 text-left text-dark"
                @click="() => showVehicleCRUDModal(item)"
                text
                >Vehicles
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('order_confirm') &&
                item.id.detail.actions.confirmation
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.confirmation"
                :class="
                  !item.id.detail.actions.confirmation
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => handleConfirmOrder(item.id.id)"
                text
                >Confirm
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('order_assign_carrier_in_action') &&
                item.id.detail.actions.assign_carrier
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.assign_carrier"
                :class="
                  !item.id.detail.actions.assign_carrier
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => handleAssignCarrier(item.id.id)"
                text
                >Assign carrier
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('order_release') &&
                item.id.detail.actions.order_release
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.order_release"
                :class="
                  !item.id.detail.actions.order_release
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => handleReleaseOrder(item.id.id)"
                text
                >Release
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('order_cancel') &&
                item.id.detail.actions.order_cancel_in_action
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.order_cancel_in_action"
                :class="
                  !item.id.detail.actions.order_cancel_in_action
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => handleCancelOrder(item.id.id)"
                text
                >Cancel
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('export') &&
                item.id.detail.actions.export_skus
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.export_skus"
                :class="
                  !item.id.detail.actions.export_skus
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => exportAction(item)"
                text
                >Export SKUs
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('export') &&
                item.id.detail.actions.export_packing_list_summary
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.export_packing_list_summary"
                :class="
                  !item.id.detail.actions.export_packing_list_summary
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => exporPackingSlip(item)"
                text
                >Packing slip summary
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('export') &&
                item.id.detail.actions.export_parcel
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.export_parcel"
                :class="
                  !item.id.detail.actions.export_parcel
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => actionExportParcel(item)"
                text
                >Export parcel
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('export') &&
                item.id.detail.actions.export_allocation_records
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.export_allocation_records"
                :class="
                  !item.id.detail.actions.export_allocation_records
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => actionExportAllocationRecord(item)"
                text
                >Export allocation
              </v-btn>
            </div>
            <div
              v-if="item.id.detail.actions.update_amazon_package_information"
            >
              <v-btn
                class="w-100 text-left"
                :disabled="
                  !item.id.detail.actions.update_amazon_package_information
                "
                :class="
                  !item.id.detail.actions.update_amazon_package_information
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => amazonPackingInformation(item)"
                text
                >Update amazon package
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('print') &&
                item.id.detail.actions.print_order_boxes
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.print_order_boxes"
                :class="
                  !item.id.detail.actions.print_order_boxes
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => printOrderBoxes(item)"
                text
                >Print Boxes
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('order_cancel') &&
                item.id.detail.actions.cancel_order_cases
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.cancel_order_cases"
                :class="
                  !item.id.detail.actions.cancel_order_cases
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => cancelOrderCases(item.id.id)"
                text
                >Cancel Order Cases
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('print_rco_picking_list') &&
                item.id.detail.actions.print_picking_list
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.print_picking_list"
                :class="
                  !item.id.detail.actions.print_picking_list
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => printPickingList(item)"
                text
                >Print Picking List
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('export') &&
                item.id.detail.actions.export_cases
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.export_cases"
                :class="
                  !item.id.detail.actions.export_cases
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => printOrderCases(item)"
                text
                >Export Order Cases
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('instant_order_allocation') &&
                item.id.detail.actions.instant_allocation
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.instant_allocation"
                :class="
                  !item.id.detail.actions.instant_allocation
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => instantAllocation(item)"
                text
                >Instant Allocation
              </v-btn>
            </div>
            <div
              v-if="
                permissionChecker('reset_replenishment') &&
                item.id.detail.actions.reset_replenishment
              "
            >
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.reset_replenishment"
                :class="
                  !item.id.detail.actions.reset_replenishment
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => resetReplenishment(item)"
                text
                >Reset Replenishment
              </v-btn>
            </div>
            <div v-if="permissionChecker('picking_list')">
              <v-btn
                class="w-100 text-left"
                :disabled="
                  !item.id.detail.actions.export_consolidated_pick_list
                "
                :class="
                  !item.id.detail.actions.export_consolidated_pick_list
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => pickListAction(item, 'consolidated')"
                text
                >Consolidated Pick List
              </v-btn>
            </div>
            <div v-if="permissionChecker('picking_list')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.export_detailed_pick_list"
                :class="
                  !item.id.detail.actions.export_detailed_pick_list
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => pickListAction(item, 'detailed')"
                text
                >Detailed Pick List
              </v-btn>
            </div>
            <div v-if="permissionChecker('export_hscode_summary')">
              <v-btn
                class="w-100 text-left"
                :disabled="
                  !item.id.detail.actions.export_hs_code_summary_report
                "
                :class="
                  !item.id.detail.actions.export_hs_code_summary_report
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => exportHSCodeSummary(item.id.id)"
                text
                >HS Code Summary
              </v-btn>
            </div>
            <div v-if="permissionChecker('enable_email_services_for_return')">
              <v-btn
                class="w-100 text-left"
                :disabled="
                  !item.id.detail.actions.enable_email_services_for_return
                "
                :class="
                  !item.id.detail.actions.enable_email_services_for_return
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => enableEmailService(item)"
                text
                >Enable email service for return
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </template>
      <!-- column end -->
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setItemPerPage"
      :getitemPerPage="getItemPerPage"
      :getpageNumber="getPageNumber"
      :setpageNumber="setPageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <!-- <EditItem
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="editModal"
    ></EditItem> -->
    <EditItem
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="editModal"
      :showNextOrder="showNextOrder"
      :showPrevOrder="showPrevOrder"
      :extraData="extraData"
      :itemForAction="editItemIdData"
    ></EditItem>
    <EditItem2
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="editModal2"
      :showNextOrder="showNextOrder"
      :showPrevOrder="showPrevOrder"
      :extraData="extraData"
      :itemForAction="editItemIdData"
    ></EditItem2>
    <QueueUploadModal
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="uploadDoc"
    />
    <VehicleCRUDModal
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="vehicleModal"
    />
    <HscodeDialog
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="hscodeDialog"
    />
    <StatusUpdateModal
      ref="stausUpdate"
      v-if="status_update_data"
      :statusDetails="status_update_data"
      :refresher="updateTableContent"
      :key="status_update_data.id"
    />
    <Update3PLAWBModal
      ref="update3PLAWBModal"
      :refresher="updateTableContent"
    />
    <AmazonPackageUpdate
      ref="amazonPackageUpdate"
      :item="amazonPackageInfoItem"
      :refresher="updateTableContent"
    />
    <v-dialog v-model="dialog2" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>Select Hold On Reason</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="holdOn_reason" column>
            <v-radio
              v-for="item in extraData.hold_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            ></v-radio>
          </v-radio-group>

          <v-menu
            v-if="holdOn_reason === 1030"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="holdOn_date"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="holdOn_date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog2 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionOnHold">
            On Hold
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>Cancel Reason</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="cancel_reason" column>
            <v-radio
              v-for="item in extraData.cancel_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog3 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionCancel">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  EXPORT_TABLE_DATA,
  SET_SERVER_DATA_FOR_ORDERS,
  SET_SHOW_ORDERS_ACTION_BTN,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  SET_TABLE_SORTORDER,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/orders.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";

import EditItem from "@/own/components/fulfillment/orders/EditItem.vue";
import AddItem from "@/own/components/fulfillment/orders/AddItem.vue";
import EditItem2 from "@/own/components/fulfillment/orders/EditItem2.vue";
import AddItem2 from "@/own/components/fulfillment/orders/AddItem2.vue";
import Print from "@/own/components/fulfillment/orders/Print.vue";
import Others from "@/own/components/fulfillment/orders/Others.vue";

import ChangeStatus from "@/own/components/fulfillment/orders/ChangeStatus.vue";
import HscodeDialog from "@/own/components/fulfillment/orders/popup/HscodeDialog.vue";
import Update3PLAWBModal from "@/own/components/fulfillment/orders/popup/Update3PLAWBModal";

import ComponentSelector from "@/own/components/datatable/ComponentSelector.vue";

import Pagination from "@/own/components/pagination/Pagination.vue";
import Swal from "sweetalert2";
import DatatableDetail from "@/own/components/datatable/DatatableDetailNew";
import ApiService from "@/core/services/api.service";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar";
import ExportSidebar from "@/own/components/sidebars/ExportSidebar";
import QueueUploadModal from "@/own/components/Modals/QueueUploadModal";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import ActionsButton from "@/own/components/fulfillment/orders/ActionsButton";
import StatusUpdateModal from "@/own/components/fulfillment/orders/StatusUpdateModal.vue";
import UploadSelect from "@/own/components/UploadSelect.vue";
import VehicleCRUDModal from "@/own/components/fulfillment/orders/VehicleCRUDModal.vue";
import AmazonPackageUpdate from "@/own/components/fulfillment/orders/AmazonPackageUpdate.vue";
import SwalService from "@/core/services/swal.service";

export default {
  name: "DataTable",
  mixins: [getPageWidthHeight],
  components: {
    ActionsButton,
    QueueUploadModal,
    DatatableDetail,
    FilterSidebar,
    ExportSidebar,
    Pagination,
    Print,
    Others,
    ComponentSelector,
    EditItem,
    AddItem,
    EditItem2,
    AddItem2,
    ChangeStatus,
    HscodeDialog,
    StatusUpdateModal,
    UploadSelect,
    Update3PLAWBModal,
    VehicleCRUDModal,
    AmazonPackageUpdate,
  },
  props: {
    disableSelectAllCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    toolBarVisionState: false,
    selected: [],
    allSelected: false,
    offset: true,
    columnsModal: false,

    editItemId: null,
    updateStatusItemId: null,
    dialog2: false,
    dialog3: false,
    cancel_reason: null,
    holdOn_reason: null,
    holdOn_date: null,
    menu2: false,
    status_filter: null,
    orderForStatusUpdate: null,
    status_update_data: null,
    isUpdateStatusItemsMultiple: true,
    amazonPackageInfoItem: null,
  }),
  beforeMount() {
    this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {}).then(() => {});
    if (this.selectedWarehouse) {
      this.$store
        .dispatch(UPDATE_TABLE_DATA, {
          ...this.$store.getters.getORDERSTableState,
          warehouse_id: this.selectedWarehouse,
        })
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    } else {
      this.$store.commit(SET_PAGE_LOADING, false);
    }
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    status_filter_data: function () {
      // console.log(this.filters);
      return this.filters.filter((data) => data.name === "orderStatus")[0]
        .values;
    },
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.mdAndUp ? true : this.toolBarVisionState;
    },
    //pagination
    getItemPerPage: function () {
      return this.$store.getters.getORDERSTableState.per_page;
    },
    getPageNumber: function () {
      return this.$store.getters.getORDERSTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getORDERSTableData.total;
    },
    pageCount: function () {
      return this.$store.getters.getORDERSTableData.last_page;
    },
    //filters
    filters: function () {
      let filters = null;
      try {
        filters = this.$store.getters.getORDERSTableFilters;
      } catch {
        filters = [];
      }
      return filters;
    },
    actions: function () {
      return [
        {
          title: "View",
          method: "showItemDetail",
          isVisible: this.permissionChecker("update"),
        },
        // {
        //   title: "Edit",
        //   method: "editItem",
        //   isVisible: this.permissionChecker("update"),
        // },
        {
          title: "Remove",
          method: "removeItem",
          isVisible: this.permissionChecker("destroy"),
        },
        {
          title: "Upload doc",
          method: "showUploadDocModal",
          isVisible: this.permissionChecker("upload_doc"),
        },
      ];
    },
    table_permissions: function () {
      if (this.$store.getters.getORDERSTablePermissions) {
        return this.$store.getters.getORDERSTablePermissions.map(function (
          perm
        ) {
          return perm.name;
        });
      } else {
        return false;
      }
    },
    table_properties: function () {
      return this.$store.getters.getORDERSTableProperties;
    },
    itemPerPage: function () {
      return this.$store.getters.getORDERSTableState.per_page;
    },
    orderBy: {
      get: function () {
        return this.$store.getters.getORDERSTableState.sortBy;
      },
      set: function (newValue) {
        let state = {
          ...this.$store.getters.getORDERSTableState,
          warehouse_id: this.selectedWarehouse,
        };
        if (newValue) {
          this.$store.commit(SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(UPDATE_TABLE_DATA, state);
        } else {
          this.$store.commit(SET_TABLE_SORTBY, "");
          this.$store.dispatch(UPDATE_TABLE_DATA, state);
        }
        this.$store.commit(SET_PAGE_LOADING, false);
      },
    },
    sortOrder: {
      get: function () {
        let sortOrder = true;
        if (this.$store.getters.getORDERSTableState.sortOrder === "asc") {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function (newValue) {
        let state = {
          ...this.$store.getters.getORDERSTableState,
          warehouse_id: this.selectedWarehouse,
        };
        if (newValue === true) {
          this.$store.commit(SET_TABLE_SORTORDER, "desc");
        } else {
          this.$store.commit(SET_TABLE_SORTORDER, "asc");
        }
        this.$store.dispatch(UPDATE_TABLE_DATA, state).then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      },
    },
    headers: function () {
      /**
       * @type {[
       {
       value: string,
       text: string,
       type: string,
       sortable: string,
       exportable: string,
       visible: string,
       mobile_visible: string,
       align: string,
       itemClass: string,
       width: string,
       class: string,
       export_order: integer,
       }
       ]}
       */
      let headers = false;
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.$store.getters.getORDERSTableHeaders) {
          headers = this.$store.getters.getORDERSTableHeaders.filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers = this.$store.getters.getORDERSTableHeaders.filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        }
      } else {
        if (this.$store.getters.getORDERSTableHeaders) {
          headers = this.$store.getters.getORDERSTableHeaders.filter(
            (col) => col.visible === true
          );
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers = this.$store.getters.getORDERSTableHeaders.filter(
            (col) => col.visible === true
          );
        }
      }
      headers.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        header.width = undefined;
        if (header.value === "aging_day")
          header.class =
            "text-center text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        if (header.value === "action") {
          header.class =
            "pr-10 text-end text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return headers;
    },
    exportHeaders: function () {
      let headers = null;
      try {
        headers = this.$store.getters.getORDERSTablePermissions.filter(
          (col) => col.name === "export"
        )[0].columns;
      } catch (error) {
        headers = [];
      }
      return headers;
    },
    types: function () {
      return this.$store.getters.getORDERSPrintTypes;
    },

    actionsButtonTypes: function () {
      let types = [];

      const typeNames = [
        // "Reset to confirmed",
        "Reset to shipped",
        // "Sync with WMS",
        // "Update aging",
        "3PL status sync",
        // "Remove POD",
        // "Unbundle",
        "Cancel",
        "Delete",
        "Lock",
        "Unlock",
        "Cancel Allocation",
        "Reassign Carrier",
        "Remove Shipped",
        "Create Return Asn",
        "Reset Replenishment",
        "Mark as RTS",
      ];

      const typesUrl = [
        // "order_reset_to_confirmed",
        "order_reset_to_shipped",
        // "order_sync_with_wms",
        // "order_update_aging",
        "order_3pl_status_sync",
        // "remove_pod",
        // "unbundle",
        "order_cancel",
        "destroy",
        "order_lock",
        "order_unlock",
        "cancel_allocation",
        "order_reassign_carrier_bulk",
        "order_remove_shipped",
        "return_order_asn_create",
        "reset_replenishment",
        "mark_as_rts",
      ];

      typesUrl.forEach((item, index) => {
        if (
          this.permissionChecker(item === "order_unlock" ? "order_lock" : item)
        )
          types.push({
            name: typeNames[index],
            url: item,
          });
      });

      return types;
    },
    items: function () {
      let items;
      try {
        items = this.$store.getters.getORDERSTableData.data;
      } catch {
        items = [];
      }

      return items;
    },
    extraData() {
      return this.$store.getters.getORDERSExtraData;
    },
    editItemIdData() {
      return this.editItemId;
    },
    // call_statuses() {
    //   return this.$store.getters.getORDERSCallStatuses;
    // },
    getQFilterStatusLabel() {
      return this.status_filter
        ? this.status_filter_data.filter(
            (data) => data.index === this.status_filter
          )[0].text
        : "Status";
    },
    getIsUpdateStatusItemsMultiple() {
      return this.isUpdateStatusItemsMultiple;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    ordersPermissions: function () {
      return this.$store.getters.getORDERSTablePermissions;
    },
  },
  methods: {
    onStatusFilterChange(val) {
      this.status_filter = val;
      this.$refs.filterSidebar.$refs.filter.clearFiltersState();
      if (val !== false) {
        this.setTableFiltersValues([{ name: "orderStatus", value: val }]);
      } else {
        this.setTableFiltersValues([{ name: "orderStatus", value: "" }]);
      }
      this.$nextTick(() => {
        this.submitFilterValues();
      });
    },
    showCancelModal() {
      if (!this.dialog3) {
        this.cancel_reason = null;
        if (this.selected.length > 0) {
          this.dialog3 = !this.dialog3;
        } else {
          Swal.fire({
            title: "Warning",
            text: `Please, select item!`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } else {
        this.dialog3 = !this.dialog3;
      }
    },
    actionCancel() {
      if (this.cancel_reason) {
        this.pageLoad(true);
        this.dialog3 = false;
        const data = {
          order_ids: this.selected.map((item) => item.id.id),
          reason: this.cancel_reason,
        };
        ApiService.post(`/order_management/pending_audit_orders/cancel`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Cancel reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoad(false);
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      }
    },
    showHoldOnModal() {
      if (!this.dialog2) {
        this.holdOn_reason = null;
        this.holdOn_date = null;
        if (this.selected.length > 0) {
          this.dialog2 = !this.dialog2;
        } else {
          Swal.fire({
            title: "Warning",
            text: `Please, select item!`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } else {
        this.dialog2 = !this.dialog2;
      }
    },
    actionOnHold() {
      if (this.holdOn_reason) {
        this.pageLoad(true);
        this.dialog2 = false;

        const data = {
          order_ids: this.selected.map((item) => item.id.id),
          reason: this.holdOn_reason,
          scheduled_date: this.holdOn_date,
        };
        ApiService.post(`/order_management/pending_audit_orders/hold`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `On Hold reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoad(false);
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      }
    },
    deleteItem(item) {
      const index = this.selected.findIndex((el) => el.id === item.id);
      index > -1 && this.selected.splice(index, 1);
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length < this.getItemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getItemPerPage * this.getpageNumber - this.getItemPerPage;
          innerItems = this.items.slice(startFrom, this.getItemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
    onResize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toolBarVisionState = false;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        this.toolBarVisionState = true;
      }
    },
    // editItem(item) {
    //   // this.$refs.editModal.$props.itemForAction = item;
    //   this.$store.commit(SET_ITEM_FOR_ACTION, item);
    //   this.$refs.editModal.toggleModal();
    // },
    actionReopen(id) {
      this.actionWarning("remove_pod", () => {
        this.pageLoad(true);
        let data = { id: id };
        ApiService.post("/fulfillment/orders/reopen", data)
          .then(() => {
            Swal.fire("Modified!", "Order modified!", "success");
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      });
    },
    // actionCall(item) {
    //   this.$refs.orderCall.$props.item = item;
    //   this.$refs.orderCall.toggleModal();
    // },
    // pagination
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setPageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    // pagination end
    // filters
    setTableFiltersValues(val) {
      this.$store.commit(SET_TABLE_FILTER, val);
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = {
        ...this.$store.getters.getORDERSTableState,
        warehouse_id: this.selectedWarehouse,
      };
      state.page = 1;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    resetFilterValues() {
      this.$store.commit(SET_TABLE_FILTER, []);
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = {
        ...this.$store.getters.getORDERSTableState,
        warehouse_id: this.selectedWarehouse,
      };
      this.$store.dispatch(UPDATE_TABLE_DATA, state).then(() => {
        this.$store.commit(SET_PAGE_LOADING, false);
      });
      this.selected = [];
    },
    // filters end
    /**
     * checks permission and return true/false
     * @types {boolean}
     * example "view","create","update","destroy","show","generate","status","print","activate","upload"
     **/
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    loadDetailData(id, isEditable = true, toggle = true) {
      // loadDetailData(id) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { order_id: id };
      ApiService.post("/fulfillment/orders/show", data)
        .then((response) => {
          // this.$store.commit(SET_ITEM_FOR_ACTION, item);
          this.$store.commit(SET_PAGE_LOADING, false);

          this.$store.commit(SET_SHOW_ORDERS_ACTION_BTN, isEditable);
          this.$store.commit(SET_SERVER_DATA_FOR_ORDERS, {
            uoms: response.data.uoms,
            countries: response.data.countries,
            currencies: response.data.currencies,
            locations: response.data.locations,
            zones: response.data.zones,
            zone_groups: response.data.zone_groups,
          });
          this.$refs.otherData.setData(
            response.data.detail,
            id,
            this.loadDetailData
          );
          if (toggle) {
            this.$refs.otherData.toggleModal();
          }
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    showAddItemModal(type = null) {
      if (type === "b2c") {
        this.$refs.addItem.toggleModal();
      } else if (type === "b2b") {
        this.$refs.addItem2.toggleModal();
      }
    },
    showItemDetail(item) {
      // this.loadDetailData(item.id.id);
      this.loadDetailData(item.id.id, item.id.detail.actions.update);
    },

    update3PLAWB(id) {
      this.actionWarning("order_3pl_status_sync", () => {
        this.$store.commit(SET_PAGE_LOADING, true);
        ApiService.post("/fulfillment/orders/update_3pl_awb", { id })
          .then(({ data }) => {
            this.$store.commit(SET_PAGE_LOADING, false);
            if (data.success) this.$refs.update3PLAWBModal.toggleModal(data);
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      });
    },
    showUploadDocModal(item) {
      // this.$refs.uploadDoc.$props.itemForAction = item;
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.uploadDoc.dialog = true;
    },
    async showVehicleCRUDModal(item) {
      // this.$refs.uploadDoc.$props.itemForAction = item;
      await this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.vehicleModal.toggleModal();
    },
    handleConfirmOrder(id) {
      this.actionWarning("order_confirm", () => {
        this.pageLoad(true);
        ApiService.post("/fulfillment/orders/order_confirm", { id })
          .then(() => {
            Swal.fire("Confirmed!", "Order has been confirmed.", "success");
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      });
    },
    handleAssignCarrier(id) {
      this.actionWarning("order_assign_carrier_in_action", () => {
        this.pageLoad(true);
        ApiService.post("/fulfillment/orders/order_assign_carrier_in_action", {
          id,
        })
          .then(() => {
            Swal.fire("Reassigned!", "Order has been reassigned.", "success");
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      });
    },
    handleReleaseOrder(id) {
      this.actionWarning("order_release", () => {
        this.pageLoad(true);
        ApiService.post("fulfillment/orders/order_release_in_action", { id })
          .then(() => {
            Swal.fire("Released!", "Order has been released.", "success");
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      });
    },
    handleCancelOrder(id) {
      this.actionWarning("order_cancel", () => {
        this.pageLoad(true);
        ApiService.post("/fulfillment/orders/order_cancel_in_action", { id })
          .then(() => {
            Swal.fire("Cancelled!", "Order has been cancelled.", "success");
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      });
    },
    instantAllocation(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, allocate it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          ApiService.post("/fulfillment/orders/instant_order_allocation", {
            id: item.id.id,
          })
            .then(() => {
              Swal.fire("Success!", "Instant allocation triggered", "success");
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    pickListAction(item, type) {
      this.pageLoad(true);
      ApiService.post("/fulfillment/orders/export-picking-list", {
        id: item.id.id,
        type: type,
      })
        .then((res) => {
          // console.log(res.data);
          if (res.data?.url) {
            const link = document.createElement("a");
            link.href = res.data.url;
            link.target = "_blank";
            link.download = "orders.csv";
            link.click();
          }
          SwalService.successMessage({
            html: SwalService.messages.successifully_completed,
          });
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    resetReplenishment(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          ApiService.post("/fulfillment/orders/reset_replenishment", {
            orders: [item.id.id],
          })
            .then(() => {
              Swal.fire("Success!", "Replenishment is reset", "success");
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    exportHSCodeSummary(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.hscodeDialog.toggleModal();
    },
    enableEmailService(item) {
      Swal.fire({
        title: "Confirmation",
        text: "Do you want to enable email service for return",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          ApiService.post("/fulfillment/orders/create_platform_refund", {
            orders: [item.id.id],
          })
            .then(() => {
              Swal.fire("Success!", "Email serice enabled", "success");
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    showDetail(val, { item, isMobile = true }) {
      // this.loadDetailData(item.id.id);
      this.loadDetailData(item.id.id, item.id.detail.actions.update);
    },
    isAnyFilterSelected() {
      let isSelected = false;
      if (this.$store.getters.getORDERSTableState.filters.length > 0) {
        isSelected = true;
      }
      return isSelected;
    },
    // exports
    downloadItem(columns) {
      if (this.isAnyFilterSelected()) {
        this.$store.commit(SET_PAGE_LOADING, true);
        // console.log("order table state", this.$store.getters.getORDERSTableState);
        this.$store
          .dispatch(EXPORT_TABLE_DATA, {
            columns: columns,
            filters: this.$store.getters.getORDERSTableState.filters,
            warehouse_id: this.selectedWarehouse,
          })
          .then(() => {
            if (this.$store.getters.getORDERSExportUrl) {
              const link = document.createElement("a");
              link.href = this.$store.getters.getORDERSExportUrl;
              link.download = "Export.csv";
              link.click();

              link.remove();
            }
            setTimeout(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            }, 1500);
            this.$refs.exportSidebar.$refs.export.toggleModal();
          });
      } else {
        this.$refs.exportSidebar.$data.exportSidebar = false;
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one filter!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    // exports end
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    removeItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          ApiService.post(`/authorizations/clients/${item.id}/destroy`)
            .then(() => {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    toggleColumnsModal() {
      this.columnsModal = !this.columnsModal;
    },

    showUploadModal(type) {
      this.$router.push({
        name: "settings.uploads",
        query: { type: type },
      });
      // this.$refs.upload.toggleUploadModal();
    },
    async updateTableContent() {
      let state = {
        ...this.$store.getters.getORDERSTableState,
        warehouse_id: this.selectedWarehouse,
      };
      this.pageLoad(true);
      await this.$store.dispatch(UPDATE_TABLE_DATA, state).then(() => {
        this.$store.commit(SET_PAGE_LOADING, false);
      });
      this.$forceUpdate();
      this.selected = [];
      this.pageLoad(false);
    },

    async findNextOrderId(id) {
      // TODO: find and return next order id
      let nextIdIndex = -1;
      let nextId = null;
      let order_type = null;
      let isReadOnly = false;
      this.items.forEach((element, index) => {
        if (element.id.id === id) {
          nextIdIndex = index + 1;
        }
      });
      // console.log("index is", nextIdIndex);
      if (parseInt(this.getItemPerPage) === nextIdIndex) {
        // console.log("last item");
        if (this.pageCount > this.getPageNumber) {
          // console.log("next page exist");
          this.setPageNumber(this.getPageNumber + 1);
          let state = {
            ...this.$store.getters.getORDERSTableState,
            warehouse_id: this.selectedWarehouse,
          };
          await this.$store.dispatch(UPDATE_TABLE_DATA, state).then(() => {
            this.$forceUpdate();
            // console.log("on update", this.items[0].id.id);
          });
          // console.log("next item id is", this.items[0].id.id);
          nextId = this.items[0].id.id;
          order_type = this.items[0].order_type;
          isReadOnly = !this.items[0].id.detail.actions.update;
        }
      } else if (this.items.length - 1 < nextIdIndex) {
        throw TypeError("there is no next order");
      } else {
        try {
          nextId = this.items[nextIdIndex].id.id;
          order_type = this.items[nextIdIndex].order_type;
          isReadOnly = !this.items[nextIdIndex].id.detail.actions.update;
        } catch (e) {
          // console.log(e);
        }
      }
      // console.log("next id is", this.items[nextIdIndex].id.id, nextId);
      return [nextId, order_type, isReadOnly];
    },
    async findPrevOrderId(id) {
      // TODO: find and return next order id
      let nextIdIndex = -2;
      let nextId = null;
      let order_type = null;
      let isReadOnly = false;
      this.items.forEach((element, index) => {
        if (element.id.id === id) {
          nextIdIndex = index - 1;
        }
      });
      // console.log("index is", nextIdIndex);
      if (nextIdIndex < 0) {
        // console.log("first item");
        if (this.getPageNumber > 1) {
          // console.log("prev page exist");
          this.setPageNumber(this.getPageNumber - 1);
          let state = {
            ...this.$store.getters.getORDERSTableState,
            warehouse_id: this.selectedWarehouse,
          };
          await this.$store.dispatch(UPDATE_TABLE_DATA, state).then(() => {
            this.$forceUpdate();
            // console.log("on update", this.items[0].id.id);
          });
          nextId = this.items[parseInt(this.getItemPerPage) - 1].id.id;
          order_type = this.items[parseInt(this.getItemPerPage) - 1].order_type;
          isReadOnly =
            !this.items[parseInt(this.getItemPerPage) - 1].id.detail.actions
              .update;
        }
      } else {
        try {
          nextId = this.items[nextIdIndex].id.id;
          order_type = this.items[nextIdIndex].order_type;
          isReadOnly = !this.items[nextIdIndex].id.detail.actions.update;
        } catch (e) {
          // console.log(e);
          throw TypeError("Can not find previous order");
        }
      }
      // console.log(nextIdIndex, nextId, this.getPageNumber, this.getItemPerPage);
      return [nextId, order_type, isReadOnly];
    },
    showNextOrder(id) {
      // console.log("current id", id);
      // this.$refs.editModal.toggleModal();
      this.findNextOrderId(id)
        .then((data) => {
          // console.log("find next order ", data);
          if (data[0]) {
            this.editItem(data[0], data[1], data[2]);
            this.updateTableContent();
          }
        })
        .catch(() => {
          // console.log(e);
          this.updateTableContent();
        });
    },
    showPrevOrder(id) {
      // this.$refs.editModal.toggleModal();
      this.findPrevOrderId(id)
        .then((data) => {
          if (data[0]) {
            this.editItem(data[0], data[1], data[2]);
            this.updateTableContent();
          }
        })
        .catch(() => {
          // console.log(e);
          this.updateTableContent();
        });
    },
    editItem(id, type = null, isReadOnly = false) {
      // console.log(id, type, isReadOnly);
      this.editItemId = id;
      // if (type.trim() === "B2C") {
      //   this.$nextTick(() => {
      //     this.$refs.editModal.toggleModal(isReadOnly);
      //   });
      // } else
      if (type.trim() === "B2B" || type.trim() === "B2B Transfer") {
        this.$nextTick(() => {
          this.$refs.editModal2.toggleModal(isReadOnly);
        });
      } else {
        this.$nextTick(() => {
          this.$refs.editModal.toggleModal(isReadOnly);
        });
      }
    },
    actionExportParcel(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      // console.log("order table state", this.$store.getters.getORDERSTableState);
      ApiService.post("/fulfillment/orders/export_parcel", {
        id: item.id.id,
      }).then((res) => {
        const link = document.createElement("a");
        link.href = res.data.url;
        link.download = "Export.csv";
        link.click();
        setTimeout(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        }, 1500);
        link.remove();
      });
    },
    exporPackingSlip(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      // console.log("order table state", this.$store.getters.getORDERSTableState);
      ApiService.post("/fulfillment/orders/export-packing-list-summary", {
        type: "packing_slip",
        orders: [item.systemId],
      })
        .then((res) => {
          const link = document.createElement("a");
          link.href = res.data.url;
          link.download = "Export.csv";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
          link.remove();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    actionExportAllocationRecord(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      // console.log("order table state", this.$store.getters.getORDERSTableState);
      ApiService.post("fulfillment/orders/export_allocation_record", {
        id: item.id.id,
      }).then((res) => {
        const link = document.createElement("a");
        link.href = res.data.url;
        link.download = "Export.csv";
        link.click();
        setTimeout(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        }, 1500);
        link.remove();
      });
    },
    amazonPackingInformation(item) {
      this.amazonPackageInfoItem = item;
      this.$refs.amazonPackageUpdate.toggleModal(item);
    },
    updateStatus(item = null, is_multiple = false) {
      this.actionWarning("update_order_status", () => {
        if (is_multiple) {
          if (this.selected.length === 0) {
            Swal.fire({
              title: "Warning",
              text: "Please, select at least one order!",
              icon: "warning",
              showConfirmButton: false,
              timer: 2500,
            });
            return;
          }
          if (this.selected.length === 1) {
            this.updateStatusItemId = this.selected[0].systemId;
            this.isUpdateStatusItemsMultiple = false;
          } else {
            this.updateStatusItemId = this.selected;
            this.isUpdateStatusItemsMultiple = true;
          }
        } else {
          this.updateStatusItemId = item.systemId;
          this.isUpdateStatusItemsMultiple = false;
        }
        this.$nextTick(() => {
          this.$refs.changeStatus.toggleModal();
        });
      });
    },
    exportAction(item) {
      this.pageLoad(true);
      let data = { id: item.id.id };
      ApiService.post("/fulfillment/orders/export_skus", data)
        .then((res) => {
          const link = document.createElement("a");
          link.href = res.data.url;
          link.download = "ProblemWithExport.csv";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    printOrderBoxes(item) {
      this.pageLoad(true);
      let data = { id: item.id.id };
      ApiService.post("/fulfillment/orders/print_order_boxes", data)
        .then((response) => {
          const link = document.createElement("a");
          link.href = response.data.path;
          link.target = "_blank";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 2500);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    printOrderCases(item) {
      this.pageLoad(true);
      let data = { id: item.id.id };
      ApiService.post("/fulfillment/orders/export_order_cases", data)
        .then((response) => {
          const link = document.createElement("a");
          link.href = response.data.url;
          link.target = "_blank";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 2500);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    printPickingList(item) {
      this.pageLoad(true);
      let data = { id: item.id.id };
      ApiService.post("/fulfillment/orders/print_rco_picking_list", data)
        .then((response) => {
          const link = document.createElement("a");
          link.href = response.data.path;
          link.target = "_blank";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 2500);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    cancelOrderCases(id) {
      this.actionWarning("order_cancel", () => {
        this.pageLoad(true);
        ApiService.post("/fulfillment/orders/cancel_order_cases", { id })
          .then(() => {
            Swal.fire(
              "Cancelled!",
              "Order cases has been cancelled.",
              "success"
            );
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      });
    },
    actionWarning(actionPermission, actionFunc) {
      const isConfirmationRequired = this.ordersPermissions?.find(
        (permission) => permission.name === actionPermission
      )?.is_confirmation_required;

      if (isConfirmationRequired) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            actionFunc();
          }
        });
      } else {
        actionFunc();
      }
    },
  },
  watch: {
    selectedWarehouse() {
      if (this.selectedWarehouse) {
        this.updateTableContent();
      }
    },
  },
};
</script>
<style scoped>
.drop-item {
  font-weight: 500;
  color: rgb(98, 98, 98);
}

.drop-item:hover {
  font-weight: 600;
  background-color: #eee5ff;
  color: #8950fcff !important;
}
</style>
