<template>
  <v-dialog
    v-model="modalState"
    transition="dialog-bottom-transition"
    persistent
    width="500"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Order Update</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mt-4">
        <v-row class="pa-0">
          <v-col cols="12">
            <v-autocomplete
              label="Carrier"
              v-model="formData.carrierId"
              :items="serverData.carriers"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-select
              dense
              outlined
              clearable
              v-model="formData.carrier_account_id"
              label="Carrier account"
              item-text="text"
              item-value="index"
              :items="
                serverData.carrier_accounts.filter(
                  (item) => item.carrier_id === formData.carrierId
                )
              "
              :error-messages="carrierAccountErrors"
              @blur="$v.formData.carrier_account_id.$touch()"
              @input="$v.formData.carrier_account_id.$touch()"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              label="Payment Type"
              v-model="formData.paymenttypeId"
              :items="serverData.paymentTypes"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Delivery Number"
              v-model="formData.deliveryNo"
              dense
              outlined
              clearable
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Number Of Boxes"
              v-model="formData.numberOfBoxes"
              dense
              outlined
              clearable
              type="number"
              @change="() => validateMinValue('staff_account_limit', 0)"
            />
          </v-col>
          <v-col cols="12">
            <div
              class="col-12 d-flex align-center justify-space-between mt-0 pt-0"
            >
              <h5 class="mb-0 mt-0 pt-0 font-weight-regular">RTS</h5>
              <v-switch
                :label="rts_flag_label"
                v-model="formData.rts_flag"
                dense
                hide-details
                class="mt-0"
                :true-value="1"
                :false-value="0"
              ></v-switch>
            </div>
          </v-col>
        </v-row>
        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            @click.prevent="clearFields"
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
          >
            Clear
          </button>
          <button
            @click.prevent="submitStatusUpdate"
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  // required,
  requiredIf,
} from "vuelidate/lib/validators";

import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "Update3PLAWBModal",
  props: {
    refresher: { required: true, type: Function },
  },
  mixins: [fieldValueValidation, validationMixin],
  validations() {
    return {
      formData: {
        carrier_account_id: {
          required: requiredIf(function () {
            return this.formData.carrierId;
          }),
        },
      },
    };
  },
  data: () => ({
    modalState: false,
    formData: {
      ordersId: null,
      carrierId: null,
      carrier_account_id: null,
      paymenttypeId: null,
      deliveryNo: null,
      numberOfBoxes: null,
      rts_flag: 0,
    },
    carriers: [],
    paymentType: [],
    carrier_accounts: [],
  }),
  computed: {
    rts_flag_label() {
      return this.formData.rts_flag === 1 ? "Yes" : "No";
    },
    serverData: function () {
      // return this.serverData;
      return {
        carriers: this.carriers,
        paymentTypes: this.paymentType,
        carrier_accounts: this.carrier_accounts,
      };
    },
    carrierAccountErrors() {
      return this.handleFormValidation("carrier_account_id", this);
    },
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },

    toggleModal(data) {
      // console.log(data);
      if (!this.modalState) {
        this.carriers = [...data.carriers];
        this.paymentType = [...data.paymentType];
        this.carrier_accounts = [...data.carrier_accounts];

        this.formData.ordersId = data.order.id;
        this.formData.carrierId = data.order.carrierId;
        this.formData.paymenttypeId = data.order.paymenttypeId;
        this.formData.deliveryNo = data.order.deliveryNo;
        this.formData.numberOfBoxes = data.order.numberOfBoxes;
        this.formData.rts_flag = data.order.rts_flag;
      } else this.clearFields();
      this.modalState = !this.modalState;
    },

    submitStatusUpdate() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$store.commit(SET_PAGE_LOADING, true);

      ApiService.post(`/fulfillment/orders/update_3pl_awb_save`, {
        ...this.formData,
      })
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: "Order is updated",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.refresher();
          this.toggleModal();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    clearFields() {
      this.formData = {
        ordersId: null,
        carrierId: null,
        paymenttypeId: null,
        deliveryNo: null,
        numberOfBoxes: null,
        rts_flag: 0,
      };
    },
  },
};
</script>
