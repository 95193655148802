<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="900"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Amazon Package Update</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-575px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Body-->
        <v-row class="pt-6">
          <v-col cols="6" md="3">
            <v-text-field
              ref="weight"
              v-model="formData.weight"
              label="Weigth (Grams)"
              outlined
              type="number"
              @change="() => validateMinValue('weight', 0, 'formData')"
              dense
              clearable
              class="poppins"
              @input="$v.formData.weight.$touch()"
              @blur="$v.formData.weight.$touch()"
              :error-messages="weightErrors"
            />
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              ref="length"
              v-model="formData.length"
              label="Length(CM)"
              outlined
              type="number"
              @change="() => validateMinValue('length', 0, 'formData')"
              dense
              clearable
              class="poppins"
              @input="$v.formData.length.$touch()"
              @blur="$v.formData.length.$touch()"
              :error-messages="lengthErrors"
            />
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              ref="width"
              v-model="formData.width"
              label="Width(CM)"
              outlined
              type="number"
              @change="() => validateMinValue('width', 0, 'formData')"
              dense
              clearable
              class="poppins"
              @input="$v.formData.width.$touch()"
              @blur="$v.formData.width.$touch()"
              :error-messages="widthErrors"
            />
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              ref="height"
              v-model="formData.height"
              label="Height(CM)"
              outlined
              type="number"
              dense
              @change="() => validateMinValue('height', 0, 'formData')"
              clearable
              class="poppins"
              @input="$v.formData.height.$touch()"
              @blur="$v.formData.height.$touch()"
              :error-messages="heightErrors"
            />
          </v-col>
        </v-row>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="py-6 d-flex flex-grow-1 align-end justify-center">
          <button
            ref="submit"
            @click="clear"
            type="submit"
            class="btn btn-secondary px-5 py-3 ls1 mr-5"
          >
            Clear
          </button>
          <button
            ref="submit"
            @click="submit"
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
          >
            Save
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import ApiService from "@/core/services/api.service";

export default {
  name: "AmazonPackageUpdate",
  mixins: [validationMixin, fieldValueValidation],
  props: ["item"],
  validations: {
    formData: {
      weight: { required },
      height: { required },
      width: { required },
      length: { required },
    },
  },

  data: () => ({
    dialog: false,
    formData: {
      weight: 1,
      height: 1,
      width: 1,
      length: 1,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.clear();
      }
      this.dialog = !this.dialog;
    },
    clear() {
      this.formData = {
        weight: 0,
        height: 0,
        width: 0,
        length: 0,
      };
      this.$v.$reset();
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$store.commit(SET_PAGE_LOADING, true);

        const payload = {
          orders: [this.item.systemId],
          ...this.formData,
        };

        ApiService.post(
          "fulfillment/orders/update_amazon_package_information",
          payload
        )
          .then(({ data }) => {
            Swal.fire({
              title: "Completed",
              html: `<div>${data.messages
                .map((mes) => `<div>${mes}</div>`)
                .join("")}</div>`,
              icon: "info",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "OK",
            });
            this.toggleModal();
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
  },
  computed: {
    weightErrors() {
      return this.handleFormValidation("weight", this);
    },
    heightErrors() {
      return this.handleFormValidation("height", this);
    },
    widthErrors() {
      return this.handleFormValidation("width", this);
    },
    lengthErrors() {
      return this.handleFormValidation("length", this);
    },
  },
  mounted() {},
};
</script>
